<template>
  <card>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <div class="row">
      <div class="col-lg-12 text-left">
        <l-button
          type="default"
          wide
          outline
          @click="$router.push({ name: 'AccountMain' })"
        >
          <i class="nc-icon left-arrow"></i>

          Ana səhifə
        </l-button>
      </div>
    </div>
    <div>
      <i
        style="font-size: 28px; padding-top: 10px"
        class="nc-icon network-communication"
      ></i
      >&nbsp;&nbsp;
      <span style="font-size: 16pt"><b>Sizinlə paylaşıldı</b></span>
    </div>
    <div
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      style="padding-top: 20px"
    >
      <el-input
        type="search"
        class="mb-3"
        style="width: 200px"
        placeholder="Search records"
        v-model="search"
        aria-controls="datatables"
      />
    </div>
    <el-table
      :data="
        me.filter(
          (c) =>
            c.title.toLowerCase().includes(search.toLowerCase()) ||
            c.code.toLowerCase().includes(search.toLowerCase()) ||
            c.orgname.toLowerCase().includes(search.toLowerCase())||
            c.globalcategoryname.toLowerCase().includes(search.toLowerCase())
        )
      "
    >
      <el-table-column prop="orgname" label="Təşkilat"> </el-table-column>
      <el-table-column prop="globalcategoryname" label="Kateqoriya"> </el-table-column>
      <el-table-column label="Test">
        <template slot-scope="{ row }">
          {{ row.code + ". " + row.title }}
        </template>
      </el-table-column>
      <el-table-column prop="questioncount" :width="120" label="Sual sayıt">
      </el-table-column>
      <el-table-column prop="duration" :width="120" label="Müd.(dəq)">
      </el-table-column>
      <el-table-column label="Qüvvədən düşmə tarixi" :width="120">
        <template slot-scope="{ row }">
          <span :class="{ 'text-danger': isduedate(row.validto) }">
            {{ row.validtostr }}</span
          >
        </template>
      </el-table-column>
      <el-table-column :width="150">
        <template slot-scope="{ row }">
          <l-button
            type="primary"
            block
            outline
            @click="$router.push({ path: '/account/start/' + row.id })"
          >
            <span class="btn-label">
              <i class="fa fa-exclamation"></i>
            </span>
            Let's try
          </l-button>
        </template>
      </el-table-column>
    </el-table>
  </card>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
} from "src/components/index";
import { Table, TableColumn } from "element-ui";
import { GetSharesWithMe } from "../../services/know";
import { DateToString } from "../../services/helper";
export default {
  components: {
    Loading,
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data() {
    return {
      isLoading: false,
      me: [],
      search: "",
    };
  },
  methods: {
    getMe() {
      this.isLoading = true;
      GetSharesWithMe(this.$store.state.user.id, "", 9999).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.me = response.data.result;
        } else {
          this.me = [];
        }
      });
    },

    isduedate(dt) {
      let d = new Date(dt);
      let n = new Date();
      if (parseInt((d - n) / (24 * 3600 * 1000)) > 7) return false;
      else return true;
    },
    statusStyle(result) {
      return { "text-success": result === 1, "text-danger": result === 0 };
    },
  },
  mounted() {
    this.getMe();
  },
};
</script>


<style lang="scss" scoped>
</style>