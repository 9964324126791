<template>
  <card>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <div class="row">
      <div class="col-lg-12 text-left">
        <l-button
          type="default"
          wide
          outline
          @click="$router.push({ name: 'AccountMain' })"
        >
          <i class="nc-icon left-arrow"></i>

          Ana səhifə
        </l-button>
      </div>
    </div>
    <div>
      <i
        style="font-size: 28px; padding-top: 10px"
        class="nc-icon bookmark-add"
      ></i
      >&nbsp;&nbsp;
      <span style="font-size: 16pt"><b>Bu yaxınlarda dərc edilmişdir</b></span>
    </div>
    <div
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      style="padding-top: 20px"
    >
      <el-input
        type="search"
        class="mb-3"
        style="width: 200px"
        placeholder="Search records"
        v-model="search"
        aria-controls="datatables"
      />
    </div>
    <el-table
      :data="
        taked.filter(
          (c) =>
            c.title.toLowerCase().includes(search.toLowerCase()) ||
            c.code.toLowerCase().includes(search.toLowerCase()) ||
            c.orgname.toLowerCase().includes(search.toLowerCase())||
            c.globalcategoryname.toLowerCase().includes(search.toLowerCase())
        )
      "
    >
      <el-table-column prop="orgname" label="Org name"> </el-table-column>
      <el-table-column prop="globalcategoryname" label="Kateqoriya"> </el-table-column>
      <el-table-column label="Test">
        <template slot-scope="{ row }">
          {{ row.code + ". " + row.title }}
        </template>
      </el-table-column>
      <el-table-column prop="testtakername" label="İmtahan verən">
      </el-table-column>
      <el-table-column label="End date">
        <template slot-scope="{ row }">
          {{takeDate(row.enddate)}}
        </template>
      </el-table-column>
      <el-table-column label="Result" :width="120">
        <template slot-scope="{ row }">
          <span :class="statusStyle(row.result)">
                      <b>{{ row.resultname }}</b>
                    </span>
        </template>
      </el-table-column>
      <el-table-column :width="100">
        <template slot-scope="{ row }">
         <l-button
                      tag="button"
                      type="secondary"
                      @click="
                        $router.push({ path: '/account/result/' + row.id })
                      "
                      outline
                    >
                      <i class="nc-icon preview"></i>
                    </l-button>
        </template>
      </el-table-column>
    </el-table>
  </card>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
} from "src/components/index";
import { Table, TableColumn } from "element-ui";
import { GetTakedExams } from "../../services/know";
import { DateToString } from "../../services/helper";
export default {
  components: {
    Loading,
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  data() {
    return {
      isLoading: false,
      taked: [],
      search: "",
    };
  },
  methods: {
    getTaked() {
      this.isLoading = true;
      GetTakedExams(this.$store.state.user.id, 9999).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.taked = response.data.result;
        } else {
          this.taked = [];
        }
      });
    },
    isduedate(dt) {
      let d = new Date(dt);
      let n = new Date();
      if (parseInt((d - n) / (24 * 3600 * 1000)) > 7) return false;
      else return true;
    },
    statusStyle(result) {
      return { "text-success": result === 1, "text-danger": result === 0 };
    },
    takeDate(dt){
      return DateToString(dt);
    }
  },
  mounted() {
    this.getTaked();
  },
};
</script>


<style lang="scss" scoped>
</style>