<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <card>
      <div class="row align-items-center">
        <div class="col-12">
          <l-button type="default" wide outline @click="onBack()">
            <i class="nc-icon left-arrow"></i>

            Ana səhifə
          </l-button>
        </div>
        <div class="col-12">
          <h3 class="mb-2">Testlər</h3>
        </div>
      </div>
      <div>
        <div class="col-12" style="margin-bottom: 10px">
          <el-select
            class="select-primary"
            v-model="cat"
            placeholder="Select category"
            @change="onChange()"
          >
            <el-option
              class="select-primary"
              v-for="item in cats"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="pl-lg-0">
            <button class="btn btn-fill btn-info btn-wd" @click="onNew()">
              Yeni test
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <l-button type="info" wide outline @click="onChange()">
              Yenilə
            </l-button>
          </div>

          <el-input
            type="search"
            class="mb-3"
            style="width: 200px"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          />
        </div>
        <div class="col-sm-12">
          <el-table
            stripe
            style="width: 100%"
            :data="
              tests.filter(
                (c) =>
                  c.title
                    .toLowerCase()
                    .indexOf(searchQuery.toLowerCase()) > -1
              )
            "
            border
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <p>Təsvir:</p>
                <span v-html="parseHtml(props.row.description)"></span>
              </template>
            </el-table-column>
            <el-table-column :width="120" prop="code" label="Kod">
            </el-table-column>
            <el-table-column prop="title" label="Başlıq"> </el-table-column>
            <el-table-column :width="100" prop="duration" label="Müd. (min)">
            </el-table-column>
            <el-table-column :width="100" label="Shuffle">
              <template slot-scope="props">
                <div onclick="return false">
                  <l-checkbox :checked="props.row.shuffle === 1"> </l-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column :width="100" label="Active">
              <template slot-scope="props">
                <div onclick="return false">
                  <l-checkbox :checked="props.row.active === 1"> </l-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column :width="120" label="Actions">
              <template slot-scope="props">
                <a
                  v-tooltip.top-center="'Edit'"
                  class="btn-warning btn-simple btn-link"
                  @click="onEdit(props.row)"
                  ><i class="nc-icon d-edit"></i> </a
                >&nbsp;&nbsp;
                <a
                  v-tooltip.top-center="'Delete'"
                  class="btn-danger btn-simple btn-link"
                  @click="onDelete(props.row)"
                  ><i class="nc-icon d-remove"></i
                ></a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import {
  ShowLink,
  HtmlNewLine,
  uploadUrl,
  fileUrl,
  noimage,
} from "../../services/helper";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Dialog,
  MessageBox,
} from "element-ui";
import { GetCategories, GetTests, DeleteTest } from "../../services/know";
import { Checkbox as LCheckbox } from "src/components/index";
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    Loading,
    LCheckbox,
  },
  data() {
    return {
      searchQuery: "",
      isLoading: false,
      tests: [],
      cats: [],
      cat: "",
    };
  },
  methods: {
    onBack() {
      this.$router
        .push({
          path:
            this.$store.state.user.roleid === 1
              ? "/admin/main"
              : "/account/main",
        })
        .catch((e) => {});
    },
    showSwal(type) {
      if (type === "warning") {
        return Swal.fire({
          title: "Are you sure?",
          text: "You will not be able to recover this record!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false,
        });
      }
    },
    onNew() {
      let routeData = this.$router.resolve({
        name: "Test",
      });
      window.open(routeData.href, "_blank");
    },
    onEdit(item) {
      let routeData = this.$router.resolve({
        name: "Test",
        params: { id: item.id },
      });
      window.open(routeData.href, "_blank");
    },
    getCategories() {
      this.isLoading = true;
      GetCategories(this.$store.state.user.id).then((response) => {
        if (response.data.result !== undefined) {
          this.isLoading = false;
          this.cats = response.data.result;
          if (this.cats.length > 0) {
            this.cat = this.cats[0].id;
            this.getTests();
          }
        } else {
          this.isLoading = false;
          this.cats = [];
        }
      });
    },
    onChange() {
      this.getTests();
    },

    getTests() {
      this.isLoading = true;
      GetTests(this.cat, this.$store.state.user.id).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.tests = response.data.result;
        } else {
          if (response.data) this.notifyVue("danger", response.data);
          this.tests = [];
        }
      });
    },

    parseHtml(item) {
      let res = ShowLink(HtmlNewLine(item));
      return res;
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style lang="scss" scoped>
</style>