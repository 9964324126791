var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[_c('loading',{attrs:{"name":"lp","active":_vm.isLoading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 text-left"},[_c('l-button',{attrs:{"type":"default","wide":"","outline":""},on:{"click":function($event){return _vm.$router.push({ name: 'AccountMain' })}}},[_c('i',{staticClass:"nc-icon left-arrow"}),_vm._v(" Ana səhifə ")])],1)]),_c('div',[_c('i',{staticClass:"nc-icon bookmark-add",staticStyle:{"font-size":"28px","padding-top":"10px"}}),_vm._v(" "),_c('span',{staticStyle:{"font-size":"16pt"}},[_c('b',[_vm._v("Bu yaxınlarda dərc edilmişdir")])])]),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",staticStyle:{"padding-top":"20px"}},[_c('el-input',{staticClass:"mb-3",staticStyle:{"width":"200px"},attrs:{"type":"search","placeholder":"Search records","aria-controls":"datatables"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('el-table',{attrs:{"data":_vm.recent.filter(
        function (c) { return c.title.toLowerCase().includes(_vm.search.toLowerCase()) ||
          c.code.toLowerCase().includes(_vm.search.toLowerCase()) ||
          c.orgname.toLowerCase().includes(_vm.search.toLowerCase())||
          c.globalcategoryname.toLowerCase().includes(_vm.search.toLowerCase()); }
      )}},[_c('el-table-column',{attrs:{"prop":"orgname","label":"Org name"}}),_c('el-table-column',{attrs:{"prop":"globalcategoryname","label":"Kateqoriya"}}),_c('el-table-column',{attrs:{"label":"Test"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.code + ". " + row.title)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"questioncount","width":120,"label":"Sual sayı"}}),_c('el-table-column',{attrs:{"prop":"duration","width":120,"label":"Müd.(min)"}}),_c('el-table-column',{attrs:{"label":"Qüvvədən düşmə tarixi","width":120},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('span',{class:{ 'text-danger': _vm.isduedate(row.validto) }},[_vm._v(" "+_vm._s(row.validtostr))])]}}])}),_c('el-table-column',{attrs:{"width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('l-button',{attrs:{"type":"primary","block":"","outline":""},on:{"click":function($event){return _vm.$router.push({ path: '/account/start/' + row.id })}}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"fa fa-exclamation"})]),_vm._v(" Let's try ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }